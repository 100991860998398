// ----------------- USHG PROD ENVIRONMENT FILE --------------------------------- 
export const environment = {
  production: true,
  appVersion: 'v1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: false,
  apiUrl: 'https://messagecenter-api.ushealthgroup.com/v1.0',
  appUrl: 'https://messagecenter.ushealthgroup.com',
  b2cUrl: 'https://ushgmembers.b2clogin.com/ushgmembers.onmicrosoft.com/oauth2/v2.0',
  socketBaseUrl: 'https://messagecenter-api.ushealthgroup.com',
  clientId: 'b746b70a-0b94-4bb0-9e34-9bf771f848ad',
  appInsights: {
    instrumentationKey: 'e5d29a9c-c6d3-461b-b06c-32954fcfc310'
  }
};  
