import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, switchMap, finalize, retry } from 'rxjs/operators';

import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';

const PROSPECT_USER_URL = `${environment.apiUrl}/ProspectUser`;
const API_LOGIN_URL = `${environment.apiUrl}/Authenticate`;
const API_USERS_URL = `${environment.apiUrl}/users`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(
    private http: HttpClient,
  ) { }

  // public methods
  login(username: string, password: string): Observable<any> {
    return this.http.post<AuthModel>(API_LOGIN_URL, { username, password });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotUsername(email: string): Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get<string>(`${PROSPECT_USER_URL}/ForgotUserName?emailId=${email}`, { headers, observe: 'body', responseType: 'text' as 'json' });
  }

  getUserByToken(token): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<UserModel>(`${API_USERS_URL}`, {
      headers: httpHeaders,
    });
  }

  createPassword(password: string) {
    let formData: any = new FormData();
    formData.append("password", password);
    return this.http.post(PROSPECT_USER_URL + '/CreatePassword', formData).pipe(
      retry(1),
      map((res: any) => res)
    );
  }
}
