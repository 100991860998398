import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { Subject } from 'rxjs';

export interface NavigationRequest {
  route: string; extras?: NavigationExtras 
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private onNavigationRequest = new Subject<NavigationRequest>();
  public onNavigationRequest$ = this.onNavigationRequest.asObservable();

  changeRoute(req: NavigationRequest) {
    console.log(req)
    this.onNavigationRequest.next(req);
  }
}
