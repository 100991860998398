import { NgModule } from '@angular/core';

import { QuoteRoutingModule } from './quote-routing.module';

@NgModule({
  imports: [
    QuoteRoutingModule,
  ]
})
export class QuoteModule { }
