import { Component, HostListener } from '@angular/core';
import { ActivityService } from '../shared/services/activity.service';

@Component({
  selector: 'app-interaction',
  template: '<span></span>',
})
export class InteractionComponent {
  constructor(
    private readonly actSvc: ActivityService,
  ) {}
  
  @HostListener('window:keydown')
  @HostListener('window:mousemove')
  @HostListener('window:click')
  @HostListener('window:scroll')
  onEvent(): void {
    this.actSvc.setLastActivity();
  }

}
