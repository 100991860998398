<h1 mat-dialog-title class="d-title">{{ data?.title }}</h1>

<div *ngIf="data?.content" mat-dialog-content class="d-content">
  {{ data.content }}
</div>
<div mat-dialog-actions>
  <div
    mat-button
    mat-dialog-close
    style="color: #085ed2"
    class="left-action bt-action"
  >
    {{ data?.buttonLeft }}
  </div>
  <div mat-button mat-dialog-close style="color: #085ed2" class="bt-action">
    {{ data?.buttonRight }}
  </div>
</div>
