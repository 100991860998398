import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InteractionComponent } from './interaction.component';

@NgModule({
  declarations: [InteractionComponent],
  imports: [CommonModule],
  exports: [InteractionComponent],
})
export class InteractionModule {}
